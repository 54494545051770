<template>
    <section class="h-100">
        <div class="row mx-0 h-100">
            <div class="col-xl col-lg col-md col-sm col px-0 h-100 overflow-auto custom-scroll border-right">
                <div class="row mx-0 align-items-center px-2 border-bottom justify-content-end" style="height:56px;">
                    <div v-if="vista===1" class="btn-general px-2" @click="cerrarSoporte">
                        Finalizar reclamo
                    </div>
                    <div v-else-if="vista===4" class="btn-general px-2" @click="cerrarSoporte">
                        Finalizar soporte
                    </div>
                    <div v-else class="d-middle f-14">
                        <!-- <p class="mr-3"><b>Solicitud:</b>15 Ago. 2020</p> -->
                        <p class="mr-2"><b>Cierre: </b>{{ detalle.fecha_finalizado_momento_3 | helper-fecha('DD/MMM/YYYY HH:mm a') }}</p>
                        <el-tooltip class="item" effect="light" content="Diana mantilla" placement="bottom">
                            <img
                            class="rounded-circle border bg-whitesmoke cr-pointer"
                            width="45" height="45"
                            :src="detalle.imagen_firmada" alt=""
                            />
                        </el-tooltip>
                    </div>
                </div>
                <div class="height-chat-padre" style="height:calc(100vh - 274px);">
                    <chat
                    :tipo="2"
                    :user="id_pedido"
                    :momento="vista===5 ? 3 : vista=== 4 ? 3 : 2"
                    :finalizado="vista===5 || vista===6"
                    :fecha-finalizado="vista===5? detalle.fecha_finalizado_momento_3 :detalle.fecha_finalizado_chat"
                    @actualizar="actualizarPedidos" @actualizar-lectura="actualizarLectura"
                    />
                </div>
            </div>
            <div class="col-xl-5 col-lg-5 col-md col-sm col px-0 h-100">
                <div class="d-flex f-14 mx-0 border-bottom" style="height:56px;">
                    <div
                    v-for="tab in tabs" :key="tab.id"
                    class="col d-middle-center px-0 text-center cr-pointer"
                    :class="activa==tab.components?'border-b-red text-general-red':''"
                    @click="activa=tab.components"
                    >
                        {{ tab.nombre }}
                    </div>
                </div>
                <div class="height-components overflow-auto custom-scroll" style="height:calc(100vh - 275px);">
                    <component :is="activa" :finalizado="activa=='Chat2' || activa=='Reclamo' ? true :false" />
                </div>
            </div>
        </div>
        <modalConfirmar ref="modalCerrarSoporte" titulo="Cerrar soporte" icon="checked" mensaje="¿Desea cerrar este caso?" @guardar="finalizarReclamo" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Reclamos from '~/services/reclamos/reclamos'

export default {
    components: {
        Detalle: () => import('./components/detalle'),
        Productos: () => import('./components/productos'),
        Chat2: () => import('./components/reclamoChat'),
        Reclamo: () => import('./components/reclamo'),
    },
    props:{
        vista: {
            type: Number,
            default: 1
        },
    },
    data(){
        return {
            activa: 'Detalle',
            tabs: [
                { id: 1, nombre: 'Detalle', components: 'Detalle'},
                { id: 2, nombre: 'Productos', components: 'Productos'},
                { id: 3, nombre: 'Chat', components: 'Chat2'},
                { id: 4, nombre: 'Reclamo', components: 'Reclamo'}
            ]
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'reclamos/reclamos/id_pedido',
            detalle: 'reclamos/reclamos/detalle'
        }),

    },
    mounted(){
        this.tabSegunModulo()

    },
    methods: {
        cerrarSoporte(){
            this.$refs.modalCerrarSoporte.toggle()
        },
        tabSegunModulo(){
            if(this.$route.name  == 'admin.soporte.reclamo' || this.$route.name  == 'admin.soporte.reclamos-terminados'){// esta son 4 tabs
                this.tabs = [
                    { id: 1, nombre: 'Detalle', components: 'Detalle'},
                    { id: 2, nombre: 'Productos', components: 'Productos'},
                    { id: 3, nombre: 'Chat', components: 'Chat2'},
                    { id: 4, nombre: 'Reclamo', components: 'Reclamo'}
                ]
            }else{
                this.tabs = [
                    { id: 1, nombre: 'Detalle', components: 'Detalle'},
                    { id: 2, nombre: 'Productos', components: 'Productos'},
                    { id: 3, nombre: 'Chat', components: 'Chat2'},
                ]
            }

        },
        async finalizarReclamo(){
            try {

                const {data} = await Reclamos.finalizarReclamo(this.detalle.id, this.vista)
                this.notificacion('Mensaje', 'Reclamo finalizado', 'success')
                this.$emit('actualizar')
                this.$refs.modalCerrarSoporte.toggle()
                this.$store.commit('reclamos/reclamos/set_id_pedido', 0)
                this.limpiarDetalle()
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiarDetalle(){
            const detalle = {
                id: null,
                nombre: '',
                imagen: null,
                imagen_firmada: null,
                id_estrella: null,
                estrella: null,
                fecha_ult_reclamo:null,
                hora_ult_chat_format: null,
                cant_msg:0,
                distancia: null,
                direccion_show: null,
                cantidad_pedidos: null,
                instrucciones: null,
                billete: null
            }
            this.$store.commit('reclamos/reclamos/set_detalle', detalle)
        },
        async actualizarPedidos(){
            try {
                this.$emit('actualizar')
            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizarLectura(){
            console.log('-----  ----actualizarLecturaa-   -------', );
            await this.actualizarUltimaLectura()
        },
        async actualizarUltimaLectura(){
            try {
                const enviar ={
                    id_pedido: this.id_pedido
                }
                const {data} = await Reclamos.actualizarUltimaLectura(enviar)

            } catch (e){
                this.error_catch(e)
            }
        },

    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.alto{
    height: calc(100vh - 64px);
}
.height-chat-padre{
    height:calc(100vh - 217px) !important;
}
.height-components{
    height:calc(100vh - 217px) !important;
}

@media (max-width:1000px){
    .height-chat-padre{
        height:calc(100vh - 274px) !important;
    }
    .height-components{
        height:calc(100vh - 275px) !important;
    }
}
</style>
